<template>
  <div :class="$style['reason']">
    <IconInfo :class="$style['info']" />
    <div :class="$style['reason-title']">{{ reason }}</div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  reason: string;
}>();

const { reason } = toRefs(props);
</script>

<style module lang="scss">
.reason {
  display: flex;
  padding: var(--spacing-xs) var(--spacing-sm);
  align-items: center;
  gap: var(--spacing-3xs);
  border-radius: var(--radius-max);
  background: var(--optional-transparent-red);
  width: fit-content;

  @media screen and (max-width: $sm) {
    padding: var(--spacing-2xs) var(--spacing-sm);
  }

  .info {
    fill: var(--optional-red);

    @media screen and (max-width: $sm) {
      width: 16px;
      height: 16px;
    }
  }

  &-title {
    color: var(--optional-red);
    font-size: 14px;
    font-weight: 600;
    line-height: 110%; /* 15.4px */
    letter-spacing: -0.07px;

    @media screen and (max-width: $sm) {
      font-size: 12px;
      letter-spacing: -0.06px;
    }
  }
}
</style>
