<template>
  <div :class="$style['empty-history']">
    <div :class="$style['empty-history__descr']">
      <IconSad />
      <div :class="$style['empty-history__descr__main']">
        <Heading4>
          {{ t("topup.empty_history") }}
        </Heading4>
        <div :class="$style['empty-history__descr__main__subtitle']">
          {{ t("topup.empty_history_subtitle") }}
        </div>
      </div>
    </div>
    <SecondaryButton size="l">
      <NavLink to="/">{{ t("topup.open_shop") }}</NavLink>
    </SecondaryButton>
  </div>
</template>

<script setup lang="ts">
import Heading4 from "~/components/Shared/Heading/Heading4.vue";
import SecondaryButton from "~/components/Shared/Button/SecondaryButton.vue";
import NavLink from "~/components/Shared/NavLink/NavLink.vue";

const { t } = useI18n();
</script>

<style module lang="scss">
.empty-history {
  display: flex;
  padding: var(--spacing-11xl) 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-5xl);
  width: 100%;
  border-radius: var(--radius-6xl);
  background: var(--general-underlay-2-lvl);

  &__descr {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-2lg);
    &__main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-xs);
      &__subtitle {
        color: var(--general-transparent-light-35);
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 135%;
        letter-spacing: -0.032px;
      }
    }
  }
}
</style>
